/*************************************************
 Module javascript pour customiser les select du site
 *************************************************/

require('select2');

var selects = $('.custom-select');
var data = [];

//On récupère toutes les entités et on les mets dans un tableau
selects.filter('#form_entity').children().each(function () {
    data.push($(this));
});

selects.select2({
    placeholder: '',
    allowClear: true,
    infinite: false,
});

// Sur la page de sélection du pays, sélection du pays et soumission du formulaire lors de la validation de l'entité
selects.filter('#form_country').change(function () {
    var country = selects.filter('#form_country').val();
    if (country) {
        //on vide toutes les options du select entités
        selects.filter('#form_entity').empty().trigger('change');
        //on remplit le champ entité avec les options liées au pays sélectionné
        data.forEach(function (option) {
            if (~option.val().indexOf(country + '_')) {
                selects.filter('#form_entity').append(option).trigger('change');
            }
        });
        $('#entity-form').attr('style', 'display: block;');
        $('#send-button').attr('style', 'display: block;');
    }
});
